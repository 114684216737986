import { onDomReady } from '../../components/dynamic/observer';

class FirstScroll {
  constructor(target) {
    this.target = target;
    this.alreadyScrolled = false;
    this.bind();
  }

  bind() {
    const instance = this;
    document.addEventListener('scrolling', (e) => {
      if (window.innerWidth > 1024 && !instance.alreadyScrolled) {
        instance.alreadyScrolled = true;
        document.dispatchEvent(new CustomEvent('fastSetScrollOffset', {
          detail: { offsetY: instance.target.offsetTop },
        }));
      }

      if (e.detail.offsetY === 0) {
        this.alreadyScrolled = false;
      }
    });
  }
}
onDomReady(() => {
  const element = document.querySelector('[data-first-scroll-target]');
  if (element) {
    new FirstScroll(element);
  }
});