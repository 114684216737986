import { onDomReady } from '../../components/dynamic/observer';
import scrollToTarget from '../custom-scroll-link/scroll-to-target';

onDomReady(() => {
  const currentUrl = document.location.search;

  const params = new URLSearchParams(currentUrl);
  const targetId = params.get('target-scroll');

  if (targetId) {
    const element = document.getElementById(targetId);

    if (element) {
      setTimeout(() => {
        scrollToTarget(element, 0);
      }, 1000);
    }
  }
});