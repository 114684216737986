import { onDomChanges, onDomReady } from '../../components/dynamic/observer';
import {querySelector} from "svgo/lib/xast";

class CaseFollower {
  constructor(selector) {
    this.container = selector;
    this.object = document.querySelector('[data-case-button]');

    if (!this.object) {
      return;
    }

    this.cursor = {
      x: 0,
      y: 0,
      lastX: 0,
      lastY: 0,
    };

    this.ease = 0.1; // плавность (чем меньше тем более плавно будет)
    this.scaleEase = 0.1; // плавность (чем меньше тем более плавно будет)
    this.offset = {
      x: 0,
      y: 0,
    };
    this.scale = 0;

    this.caseContainers = [];
    this.updateContainerList();
    // this.updateContainerPosition();
    this.update();
    this.eventListeners();
  }

  updateContainerList() {
    const instance = this;
    document.querySelectorAll('[data-case-container]:not([data-initialize="true"])')
      .forEach((element) => instance.caseContainers.push(element));

    this.caseContainers
      .filter((container) => !container.dataset.initialize)
      .forEach((container) => {
        container.addEventListener('mouseenter', () => container.dataset.hover = 'true');
        container.addEventListener('mouseleave', () => container.dataset.hover = 'false');

        const exclude = container.querySelector('[data-exclude-follower]');
        if (exclude) {
          exclude.addEventListener('mouseenter', () => container.dataset.excludeHover = 'true');
          exclude.addEventListener('mouseleave', () => container.dataset.excludeHover = 'false');
        }
      });
  }

  setStyles() {
    this.object.style.transform = `matrix(1, 0, 0, 1, ${this.offset.x}, ${this.offset.y}) translate3d(-50%, -50%, 0) scale(${this.scale})`;
  }

  handleMouseenterOnContainer() {
    this.computedOffset();

    const hasHoverContainer = this.caseContainers
      .find((container) => container.dataset.hover === 'true' && container.dataset.excludeHover !== 'true');

    if (hasHoverContainer) {
      this.scale = (1 - this.scaleEase) * this.scale + this.scaleEase;
    } else {
      this.scale *= (1 - this.scaleEase) * this.scale;
    }
  }

  computedOffset() {
    this.offset.x = (1 - this.ease) * this.offset.x + this.ease * this.cursor.x;
    this.offset.y = (1 - this.ease) * this.offset.y + this.ease * this.cursor.y;
  }

  // updateContainerPosition() {
  //   const bounds = this.container.getBoundingClientRect();
  //   this.containerWidth = this.container.offsetWidth;
  //   this.containerHeight = this.container.offsetHeight;
  //   this.containerLeft = bounds.left;
  //   this.containerRight = this.containerLeft + this.containerWidth;
  //   this.containerTop = bounds.top;
  //   this.containerBottom = this.containerTop + this.containerHeight;
  // }

  update() {
    this.handleMouseenterOnContainer();
    this.setStyles();

    this.cursor.lastX = this.cursor.x;
    this.cursor.lastY = this.cursor.y;

    window.requestAnimationFrame(this.update.bind(this));
  }

  eventListeners() {
    const instance = this;

    window.addEventListener('mousemove', (e) => {
      this.cursor.x = e.clientX;
      this.cursor.y = e.clientY;
    });

    document.addEventListener('DOMContentMutated', (e) => instance.updateContainerList());
  }
}

function init() {
  const rootStyles = getComputedStyle(document.documentElement);
  const largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1025;

  if (document.body.getBoundingClientRect().width >= largeMinWidth) {
    const button = document.querySelector('[data-case-button]:not([data-initialize="true"])');
    if (button) {
      button.dataset.initialize = 'true';
      new CaseFollower(button);
    }
  }
}

onDomReady(() => init());